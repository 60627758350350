import React from 'react';

const InputField = React.forwardRef(({ value, onChange, onFocus, formatType, placeholder, focused }, ref) => {
    let formattedValue
    let totalLength
    if(formatType === 'card'){
        formattedValue = value.replace(/\D/g, '').replace(/(.{4})/g, '$1 ').trim();
        totalLength = 19
    }else if(formatType === 'date'){
      formattedValue = value.replace(/\D/g, '');

      // Automatically insert slash (/) between month and year
      if (formattedValue.length >= 2) {
          formattedValue = formattedValue.slice(0, 2) + '/' + formattedValue.slice(2, 4);
      }
      totalLength = 5; // Maximum length for MM/YY format

    }else if(formatType === 'num'){
      totalLength = 1
    }
    else{
        formattedValue = value
        totalLength = 3
    }

    const showPlaceholderAsLabel = !value;

  return (
    <input
      ref={ref}
      type="text"
      value={formattedValue}
      onChange={onChange}
      onFocus={onFocus}
      className={'text-[#454f63] bg-white  transition-all focus:border-[#80bdff]  outline-[#BFDEFF] placeholder-[#454f63] shdow-input text-xs md:text-lg border p-4 w-full rounded-xl border-neutral-200'}
       
      placeholder={placeholder}
      inputMode="numeric"
      maxLength={totalLength}
    />
  );
});


export default InputField;
