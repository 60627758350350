import React from 'react';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();

  // Do not render the Footer if the current path is "/"
  if (location.pathname === '/') {
    return null;
  }

  return (
    <div className="flex flex-col items-center w-full px-8 pb-4 md:pb-6 t-24 M">
      <div className="w-full h-[2px] border-[1px] bg-[#96D9C1] border-[#00a36a]"></div>
      <span className="text-[#78849E] text-center text-[13px] mt-4 md:mt-6 md:text-[14px] font-bold">
        Copyright © 2024 UAE PASS All rights reserved.
      </span>
    </div>
  );
};

export default Footer;
