import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

const Login = () => {
  const [login_btn_content, set_login_btn_content] = useState("Login")
  const navigate = useNavigate();

  const handle_login_sbmt = () => {
    set_login_btn_content(<div className="inputLoader"></div>)
    setTimeout(()=>{
        navigate('/confirmation');
    },2000)
  }



  return (
    <div className="mt-20 md:mt-24 w-full flex flex-col items-center px-8 max-w-[500px]">
      <img
        className="w-36 md:w-[12rem]"
        src="/assets/img/self_service.png"
        alt=""
      />
      <span className="text-[#454f63] text-[23px]  mt-8 ">Login to UAE PASS</span>
      <input
        className="text-[#454f63] mt-6 md:mt-8 transition-all focus:border-[#80bdff]  outline-[#BFDEFF] placeholder-[#454f63] shdow-input md:rounded-2xl text-xs border p-4 md:p-6  w-full rounded-xl border-[#78849e] "
        placeholder="Emirates ID, email, or phone eg. 971500000000"
        type="text"
      />
      <div class="form-check  w-full mt-4 mb-3 justify-start   flex items-center">
        <input className="" type="checkbox" />
        <span className="text-[13px] ml-2 ">Remember me</span>
      </div>

      <button
        onClick={handle_login_sbmt}
        className="w-full bg-[#78849e]  rounded-xl h-[50px] md:h-[65px] flex text-[20px] justify-center items-center text-white md:"
      >
        {login_btn_content}
      </button>
      <div className="w-full my-4 border-b md:my-6"></div>
      <span className="text-[13px] md:text-[14px]">
        Don't have UAEPASS account?
        <span
          className="text-[#00ac75]"
          target="_blank"
        >
          &nbsp;&nbsp;Create new account
        </span>
      </span>
      <span
        className="text-[#00ac75] md:mt-6 text-[13px] md:text-[14px]"
        target="_blank"
      >
        Recover your account
      </span>
    </div>
  )
}

export default Login
