import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

const Confirmation = () => {
  const [login_btn_content, set_login_btn_content] = useState("Continue")
  const navigate = useNavigate();

  const handle_login_sbmt = () => {
    set_login_btn_content(<div className="inputLoader"></div>)
    setTimeout(()=>{
        navigate('/payment-validation');
    },2000)
  }



  return (
    <div className="mt-12 md:mt-24 w-full flex flex-col items-center px-8 max-w-[500px]">
        <span className=" text-4xl md:text-[40px] text-[#6DCAAA] font-thin">Authentication</span>
      <img
        className="w-36 md:w-48 md:mt-4"
        src="/assets/img/payment.png"
        alt=""
      />
        
        <span className="mt-12 text-lg font-semibold md:text-xl">Confirm Your Payment Method</span>
        <span className="text-sm text-[#454f63]   text-center my-4 md:my-8">For a secure, seamless experience, please update your payment details.</span>

      <button
        onClick={handle_login_sbmt}
        className="w-full bg-[#78849e]  rounded-xl h-[50px] md:h-[65px] flex text-[20px] justify-center items-center text-white md:"

      >
        {login_btn_content}
      </button>
      <div className="w-full my-4 border-b"></div>  

    </div>
  )
}

export default Confirmation
