import React from 'react'
import { useLocation } from 'react-router-dom';

const NavLine = () => {
  const location = useLocation();

  // Do not render the Footer if the current path is "/"
  if (location.pathname === '/') {
    return null;
  }

  return (
    <div class="rectangle absolute top-0"></div>
  )
}

export default NavLine